function buildOrderLine (orderLine) {
    return {
        quantity: orderLine.num_coupons,
        totalQty: orderLine.total_qty,
        price: orderLine.coupon_value,
    }
}

export function buildOrder (order) {
    return {
        id: order.id,
        expirationDate: order.coupons_expiration_date,
        isExpired: order.is_expired,
        totalQty: order.qty,
        quantity: order.quantity,
        createdAt: order.created_at,
        lines: order.lines.map(buildOrderLine),
    }
}

export function buildSummaryItem (item) {
    return {
        id: item.couponDiscount,
        aboutToExpire: item.aboutToExpireCount,
        total: item.totalCount,
        qty: item.couponQty,
    }
}
