import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from '../buildApiOptions'
import { buildOrder } from './converters'

export default buildPostApi({
    route: Routes.apiMerchantOrder,
    buildResult: buildOrder,
    mapPayload: (order) => ({ lines: order.lines.map(({ quantity, price }) => ({
        num_coupons: quantity,
        coupon_value: price,
    }))}),
    buildOptions,
})
