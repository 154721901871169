import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiBusinessInformation,
    buildResult: (info) => ({
        id: info.id,
        name: info.name || '',
    }),
    buildOptions,
})
