import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiMerchantConfig,
    buildResult: ({ fee_base_price_qty: qty }) => ({
        feeBasePriceQty: qty,
    }),
    buildOptions,
})
