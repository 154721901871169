import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiGetAvailableProducts,
    buildResult: (items) => items.map((product) => ({
        id: product.name,
        title: product.name,
        price: product.qty.amount,
        currency: product.qty.currency,
        quantities: product.quantities,
    })),
    mapParams: () => {},
    buildOptions,
})
