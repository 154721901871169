import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'
import { buildOrder } from './converters'

export default buildGetApi({
    route: Routes.apiMerchantOrder,
    buildResult: (orders) => orders.map(buildOrder),
    buildOptions,
})
