import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'
import { buildOrder } from './converters'

export default buildGetApi({
    route: Routes.apiMerchantOrderShow,
    buildResult: buildOrder,
    buildOptions,
    mapParams: (id) => ({ id }),
})
