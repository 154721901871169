import Routes from '../../routes/routes'
import { buildPutApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildPutApi({
    route: Routes.apiBusinessInformation,
    buildResult: (info) => ({
        id: info.id,
        name: info.name || '',
    }),
    mapPayload: ({ name }) => ({ name }),
    buildOptions,
})
