import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiCouponList,
    mapParams: () => {},
    buildResult: (items) => {
        return items.map(item => ({
            id: item.couponDiscount,
            aboutToExpire: item.aboutToExpireCount,
            total: item.totalCount,
            qty: item.couponQty,
        }))
    },
    buildOptions,
})
